import React from 'react'
import Navigation from './navigation'
import Footer from './footer'
import Helmet from 'react-helmet'

import { FaHeart, FaEnvelopeOpenText } from 'react-icons/fa'

import { css } from 'react-emotion'

import { Jumbotron, Container, Row, Col, Button } from 'reactstrap'

const homeStyles = css`
  background-color: #fff;

  p {
    font-size: 1.3em;
    line-height: 2;
  }

  h2 {
    margin: 30px 0;
  }

  .embed-container iframe,
  .embed-container object,
  .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
  }

  .testimonial {
    background-color: #f4f4f4;
    border: 1px solid #d8d8d8;
    font-style: italic;
    padding: 40px;
    margin: 40px;
  }

  .testimonial img {
    width: 100px;
    height: auto;
    float: left;
    margin: 0 30px 20px 0;
    border-radius: 50%;
  }

  .love {
    color: #ff007d;
    font-style: italic;
    font-weight: bold;
  }

  .love svg {
    font-size: 0.8em;
  }

  ul {
    margin-left: 20px;
  }

  li {
    font-family: Georgia, Cambria, 'Times New Roman', Times, serif;
    font-weight: 700;
    font-size: 1.1em;
    line-height: 1.8em;
  }
`

class LayoutHome extends React.Component {
  render() {
    const { children } = this.props

    return (
      <div>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          meta={[{ name: 'description', content: `` }]}
          title={`Site Title`}
        />

        <header
          id="header"
          role="banner"
          itemScope="itemscope"
          itemType="http://schema.org/WPHeader"
          style={{
            backgroundColor: `#ecf0f1`,
            boxShadow: `0px 0px 2px 1px rgba(0,0,0,0.05)`,
            position: `sticky`,
            zIndex: `100`,
            top: `0px`,
          }}
        >
          <Navigation />
        </header>

        <Jumbotron
          style={{
            backgroundColor: `#45c5f7`,
            backgroundImage: `url(pages/home/header-background.png)`,
            backgroundSize: `contain`,
            backgroundRepeat: `no-repeat`,
            backgroundPosition: `center center`,
            borderRadius: `0`,
            textAlign: `center`,
          }}
        >
          <Container>
            <h1
              style={{
                color: `#fff`,
                textShadow: `2px 2px #a5a5a5`,
                fontSize: `3.5em`,
              }}
            >
              Main header
            </h1>

            <h3
              style={{
                backgroundColor: `#fff93e`,
                padding: `10px`,
                margin: `30px 20%`,
              }}
            >
              sub header
            </h3>
          </Container>
        </Jumbotron>

        <Container className={homeStyles}>
          <Row>
            <Col xs={{ size: 10, offset: 1 }}>
              <h2>Header</h2>

              <p>Content</p>
            </Col>
          </Row>
        </Container>

        <Footer />
      </div>
    )
  }
}

export default LayoutHome
